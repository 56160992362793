<div class="u-text-align-center nx-margin-top-xl nx-margin-bottom-4m">
  <div nxRow rowJustify="center">
    <div nxCol="12">
      <p nxCopytext="normal" [id]="titleId" class="nx-margin-bottom-0 u-text-align-center">{{ title }}</p>
    </div>
  </div>
  <div nxRow rowJustify="center" class="nx-margin-top-2xs">
    <div nxCol="12">
      <h2 [id]="mainTitleId" class="nx-margin-bottom-0 u-text-align-center" nxHeadline="section">
        {{ mainTitle }}
      </h2>
    </div>
  </div>

  <div *ngIf="subtitle" nxRow rowJustify="center">
    <div nxCol="12" id="sub-title" class="nx-margin-top-xs">
      <p nxCopytext="normal" [id]="subtitleId" class="nx-margin-bottom-0 u-text-align-center">
        {{ subtitle }}
      </p>
      <ng-content *ngIf="showInfoIcon" select=".info-popover"></ng-content>
    </div>
  </div>
</div>
