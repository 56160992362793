<div nxLayout="grid maxwidth" class="success-component-container">

  <div nxRow rowJustify="center" class="nx-margin-top-xl">
    <dsmkd-circle-check-animation></dsmkd-circle-check-animation>
  </div>
  <div nxRow rowJustify="center" class="nx-margin-top-2m">
    <div nxCol="6" class="u-text-align-center">
      <h1 nxHeadline="section nx-margin-bottom-xs success-message-heading">Vielen Dank!</h1>
    </div>
  </div>
  <div class="nx-margin-top-2m">
    <div nxRow rowJustify="center" *ngIf="isEmailChangeSuccessful !== undefined">
      <div *ngIf="isEmailChangeSuccessful; else errorEmailMessage" nxCol="6" class="u-text-align-left komm-data-success-display">
        <nx-icon size="s" name="check" class="komm-data-change-success-icon"></nx-icon>
        <p nxCopytext="normal" [innerHTML]="'kommdata.done.headline.detailmessage.maz.change.email' | translate"></p>
      </div>
      <ng-template #errorEmailMessage>
        <div nxCol="6" class="u-text-align-left komm-data-success-display">
          <nx-icon size="s" name="close" class="komm-data-change-success-icon"></nx-icon>
          <p nxCopytext="normal" [innerHTML]="'kommdata.error.headline.detailmessage.maz.change.email' | translate">
              </p>
        </div>
      </ng-template>
    </div>
    <div nxRow rowJustify="center" *ngIf="isMobileChangeSuccessful !== undefined">
      <div *ngIf="isMobileChangeSuccessful; else errorMobileMessage" nxCol="6" class="u-text-align-left komm-data-success-display">
        <nx-icon size="s" name="check" class="komm-data-change-success-icon"></nx-icon>
        <p nxCopytext="normal" [innerHTML]="'kommdata.done.headline.detailmessage.maz.change.mobile' | translate">
      </p>
      </div>
      <ng-template #errorMobileMessage>
        <div nxCol="6" class="u-text-align-left komm-data-success-display">
          <nx-icon size="s" name="close" class="komm-data-change-success-icon"></nx-icon>
          <p nxCopytext="normal" [innerHTML]="'kommdata.error.headline.detailmessage.maz.change.mobile' | translate">
          </p>
        </div>
      </ng-template>
    </div>

    <div nxRow rowJustify="center">
      <div nxCol="6" class="u-text-align-left komm-data-success-display">
        <nx-icon size="s" name="check" class="komm-data-change-success-icon"></nx-icon>
        <p nxCopytext="normal" [innerHTML]="'kommdata.done.headline.detailmessage.maz.change.second' | translate">
      </p>
      </div>
    </div>

    <div nxRow rowJustify="center">
      <div nxCol="6" class="u-text-align-left komm-data-success-display">
        <nx-icon size="s" name="check" class="komm-data-change-success-icon"></nx-icon>
        <p nxCopytext="normal" [innerHTML]="'kommdata.done.headline.detailmessage.maz.change.third' | translate">
      </p>
      </div>
    </div>
  </div>


  <div *ngIf="!isCustomerFromSDW" nxRow rowJustify="center" class="nx-margin-top-2m">
    <div id="back-link" nxCol="10,10,12,12" class="u-text-align-center">
      <nx-link (click)="navigateToServiceHub()" nxStyle="block">
        {{'kommdata.done.back' | translate}}
      </nx-link>
    </div>
  </div>

  <dsmkd-voc-teaser></dsmkd-voc-teaser>

</div>
