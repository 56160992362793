import {Component, ElementRef, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {Router} from '@angular/router';
import moment from 'moment';
import {DatepickerIntlService} from '../../../core/services/datepicker-intl.service';
import {AmslPersonModel} from '../../../core/models/amsl-person.model';
import {FileUploadConfigModel} from '../../../files-upload/models/file-upload-config.model';
import {BackendResponse} from '../../../core/models/backend-data-model';
import {HttpErrorResponse} from '@angular/common/http';
import {HttpErrorService} from '../../../core/services/http-error.service';
import {WeweService} from '../../../core/services/wewe.service';
import {WeweRequest} from '../../../core/models/wewe.request';
import {AppStateService} from '../../../core/services/app-state.service';
import {NxDatepickerIntl} from '@aposin/ng-aquila/datefield';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {WeweResponse} from '../../../core/models/wewe.response';
import {AmslPersonService} from '../../../core/services/amsl-person.service';

@Component({
  selector: 'dsmkd-wewe-form',
  templateUrl: './wewe-form.component.html',
  styleUrls: ['./wewe-form.component.scss'],
  providers: [
    {provide: NxDatepickerIntl, useClass: DatepickerIntlService}
  ]
})
export class WeweFormComponent implements OnInit {

  form: FormGroup;

  maxDate: moment.Moment;

  amslPersonModel: AmslPersonModel = {} as AmslPersonModel;

  fileUploadConfig: FileUploadConfigModel = {
    pClientID: 'dss-wewe',
    pCloneID: 'dss-wewe-clone',
    pUserIP: 'noIP',
    pUserID: '',
  };

  constructor(private readonly fb: FormBuilder,
              private readonly appStateService: AppStateService,
              private readonly weweService: WeweService,
              private readonly httpErrorService: HttpErrorService,
              private readonly router: Router,
              private readonly elementRef: ElementRef,
              private readonly titleService: Title,
              private readonly translateService: TranslateService,
              private readonly location: Location,
              private readonly amslPersonService: AmslPersonService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.translateService.get('wewe.global.page.title').subscribe(value => this.titleService.setTitle(value));
    this.maxDate = moment();
    this.amslPersonService.getPerson().subscribe(res => {
      const amslPerson = res.body;
      amslPerson.birthday = new Date(res.body.birthday);
      this.amslPersonModel = amslPerson;
      if (this.amslPersonModel.mazAccount) {
        this.maz.disable();
      }
    },
    () => {
      this.router.navigate(['error', {id: 99999}]);
    });

    this.weweService.canWeweProcessedForAbsPerson()
      .subscribe(res => {
      }, error => {
        // special handling for the sabs-2023 error and person verstorben
        this.evaluateErrorResponse(error, [20007, 20012], false);
      });
  }

  private createForm() {
    this.form = this.fb.group({
      // the communication data are select-lists in form of checkboxes.
      // Therefore, no format validation necessary.
      fileIds: ['', Validators.required],
      communicationData: this.fb.group({
        email: new FormControl(null),
        mobilePhone: new FormControl(null),
        landlinePhone: new FormControl(null)
      },
      {
        validators: this.validateCommunicationData()
      }),
      consent: this.fb.group({
        wewe: new FormControl(false),
        maz: new FormControl(),
        date: new FormControl('', [Validators.required]),
      },
      {
        validators: this.validateConsent()
      }
      ),
      agentConfirmation: new FormControl(false, [Validators.requiredTrue])
    },
    {
      validators: this.validateMazAndCommunicationData()
    }
    );
  }

  private validateConsent(): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      const weweKey = 'wewe';
      const mazKey = 'maz';
      const weweControl = group.controls[weweKey];
      const mazControl = group.controls[mazKey];
      if (!weweControl.value && !mazControl.value) {
        // In order to get the controls a red frame:
        // if a control is touched, the error will be shown.
        // In this case, we have to ensure that both are touched
        // (even if the user has only clicked one)
        // in order to get the errors shown.
        if (weweControl.touched || mazControl.touched) {
          weweControl.markAsTouched();
          mazControl.markAsTouched();
        }
        weweControl.setErrors({minCheckbox: true});
        mazControl.setErrors({minCheckbox: true});
        return {minCheckbox: true};

      } else {
        weweControl.setErrors(null);
        mazControl.setErrors(null);
        return null;
      }
    };
  }

  private getStringArray(formGroup: FormGroup, path: string): string[] {
    return formGroup.get(path).value || [] as string[];
  }

  private validateCommunicationData(): ValidatorFn {
    // At least one communication data has to be selected
    return (group: FormGroup): ValidationErrors | null => {
      const emails = this.getStringArray(group, 'email');
      const mobiles = this.getStringArray(group, 'mobilePhone');
      const landLines = this.getStringArray(group, 'landlinePhone');

      if (emails.length === 0 && mobiles.length === 0 && landLines.length === 0) {
        return {minOneCommunicationData: true};
      }
      return null;
    };
  }

  private validateMazAndCommunicationData(): ValidatorFn {
    // is maz is selected => email and mobile are required
    // root form group == form
    return (group: FormGroup): ValidationErrors | null => {
      const emails = this.getStringArray(group, 'communicationData.email');
      const mobiles = this.getStringArray(group, 'communicationData.mobilePhone');
      if (group.get('consent.maz').value && (emails.length === 0 || mobiles.length === 0)) {
        return {mazAndCommunicationData: true};
      }

      if (group.get('consent.maz').value &&
        (emails.length > 1 || mobiles.length > 1)) {
        return {mazAndCommunicationDataMaxOne: true};
      }
      return null;
    };
  }

  // Naming convention: same name as used by form builder
  get communicationData(): FormGroup {
    return this.form.get('communicationData') as FormGroup;
  }

  get email(): FormControl {
    return this.communicationData.get('email') as FormControl;
  }

  get mobilePhone(): FormControl {
    return this.communicationData.get('mobilePhone') as FormControl;
  }

  get landlinePhone(): FormControl {
    return this.communicationData.get('landlinePhone') as FormControl;
  }

  get consent(): FormGroup {
    return this.form.get('consent') as FormGroup;
  }

  get wewe(): FormControl {
    return this.consent.get('wewe') as FormControl;
  }

  get maz(): FormControl {
    return this.consent.get('maz') as FormControl;
  }

  get date(): FormControl {
    return this.consent.get('date') as FormControl;
  }

  get agentConfirmation(): FormControl {
    return this.form.get('agentConfirmation') as FormControl;
  }

  get fileIds(): FormControl {
    return this.form.get('fileIds') as FormControl;
  }

  private recursivelyValidateAllFormFields(control: AbstractControl) {
    if (!control) {
      return;
    }
    // manually trigger the validation if available:
    if (control.validator) {
      control.validator(control);
    }
    // traverse the tree of form controls.
    if (control instanceof FormGroup) {
      Object.keys(control.controls).forEach(path => {
        this.recursivelyValidateAllFormFields(control.get(path));
      });
    } else if (control instanceof FormArray) {
      control.controls.forEach(element => this.recursivelyValidateAllFormFields(element));
    }
  }

  onSubmit() {
    this.recursivelyValidateAllFormFields(this.form);
    if (!this.form.valid) {
      // If we have more forms with this behaviour, we can make a directive like
      // https://github.com/Ismaestro/ngx-scroll-to-first-invalid/blob/master/
      // projects/ngx-scroll-to-first-invalid-lib/src/ngx-scroll-to-first-invalid.directive.ts
      // mark as touched so that all validation errors are visible
      this.form.markAllAsTouched();
      // first do pending processing, then scroll to the first nx-error element:
      setTimeout(() => {
        const formControlInvalid = this.elementRef.nativeElement.querySelector('nx-error');
        if (formControlInvalid) {
          formControlInvalid.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
      }, 50);
    } else {
      const request = Object.assign({
        auth: this.appStateService.authInfo,
        email: this.email.value,
        mobilePhone: this.mobilePhone.value,
        landlinePhone: this.landlinePhone.value,
        consentDate: this.date.value.format('YYYY-MM-DD'),
        wewe: this.wewe.value,
        maz: this.maz.value,
        signature: this.agentConfirmation.value,
        // upload has per defauld a form controls fieldIds. One is required:
        fileId: this.fileIds.value[0]
      }) as WeweRequest;


      this.weweService.sendWewe(request).subscribe((backendResponse) => {
        this.evaluateResponse(backendResponse);
      }, error => {
        this.evaluateErrorResponse(error, [20004, 20007, 20008, 20012], true);
      });
    }
  }

  navigateBack() {
    this.location.back();
  }

  private evaluateResponse(backendResponse: BackendResponse<WeweResponse>): void {
    this.appStateService.weweResponse.weweState = backendResponse.body.weweState;
    this.appStateService.weweResponse.mazState = backendResponse.body.mazState;
    const id = this.amslPersonModel.personType === 'JURPERS' ? 'jurpers' : 'default';
    this.router.navigate(['wewe', 'success', {id}]);
  }

  /**
   * Evaluates the error and navigates to the error page.
   * @param errorResponse The response.
   * @param errorCodes The error codes with special handling, i.e. with known error page texts.
   * @param defaultHandling true, if all not special handled error codes should be covered by default handling.
   */
  private evaluateErrorResponse(errorResponse: HttpErrorResponse, errorCodes: number[], defaultHandling: boolean): void {
    if (errorResponse && errorResponse.error && 'errors' in errorResponse.error) {

      // payload.error should be a BackendResponse, but here we try not to make to much assumptions
      const codes: Array<number> = new Array<number>();
      // in BackendResponse code is a number.
      const codeKey = 'code';
      errorResponse.error.errors.filter(e => codeKey in e).forEach(e => codes.push(e[codeKey]));

      // The error codes with special handling:
      const hanledErrorCodes: Set<number> = new Set<number>(errorCodes);

      const errorCodeToHandle: number = codes.find(errorCode => hanledErrorCodes.has(errorCode));
      if (errorCodeToHandle) {
        this.router.navigate(['error', {id: errorCodeToHandle}]);
        return;
      }
    }

    if (defaultHandling) {
      this.httpErrorService.handleHttpError(errorResponse);
    }
  }
}
