import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {BackendResponse} from '../models/backend-data-model';
import {KommDataItem, KommDataModel} from '../models/kommdata.model';
import {KommDataChangeBackendModel} from '../../komm-data-change/model/komm-data-change-backend.model';
import {KommDataChangeResponseModel} from '../../komm-data-change/model/komm-data-change-response.model';
import {AddressModel} from '../models/address.model';
import {KommdataAddResponse} from '../models/kommdata-add-response';
import {KommdataAddRequest} from '../models/kommdata-add-request';
import {KommDataDeleteResponse} from '../models/komm-data-delete-response.model';

@Injectable({
  providedIn: 'root'
})
export class KommDataService {

  private readonly kommDataUrl = '/kommdata/api/rest/dss';

  constructor(private readonly http: HttpClient) {
  }

  getKommData(trackingSource: string): Observable<BackendResponse<KommDataModel>> {
    return this.http.get<BackendResponse<KommDataModel>>(`${this.kommDataUrl}/kommdata/?trackingSource=${trackingSource}`);
  }

  postChangeMAZKommData(kommDataChangeBackendModel: KommDataChangeBackendModel): Observable<BackendResponse<KommDataChangeResponseModel>> {
    return this.http.post<BackendResponse<null>>(`${this.kommDataUrl}/kommdata/change`, kommDataChangeBackendModel);
  }

  hasDigitalId(): Observable<BackendResponse<boolean>> {
    return this.http.get<BackendResponse<null>>(`${this.kommDataUrl}/kommdata/digitalId`);
  }

  getAddress(): Observable<BackendResponse<AddressModel>> {
    return this.http.get<BackendResponse<AddressModel>>(`${this.kommDataUrl}/address`);
  }

  addKommdata(kommdataAddRequest: KommdataAddRequest): Observable<BackendResponse<KommdataAddResponse>> {
    return this.http.post<BackendResponse<KommdataAddResponse>>(`${this.kommDataUrl}/kommdata/add`, kommdataAddRequest);
  }

  deleteKommData(kommDataToDelete: KommDataItem): Observable<BackendResponse<KommDataDeleteResponse>> {
    return this.http.post<BackendResponse<KommDataDeleteResponse>>(`${this.kommDataUrl}/kommdata/delete`, kommDataToDelete);
  }
}
