import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {NxInputModule} from '@aposin/ng-aquila/input';
import {NxFormfieldModule} from '@aposin/ng-aquila/formfield';
import {NxIconModule} from '@aposin/ng-aquila/icon';
import {NxLinkModule} from '@aposin/ng-aquila/link';
import {NxGridModule} from '@aposin/ng-aquila/grid';
import {NxButtonModule} from '@aposin/ng-aquila/button';
import {NxMessageModule} from '@aposin/ng-aquila/message';
import {NxHeadlineModule} from '@aposin/ng-aquila/headline';
import {NxListModule} from '@aposin/ng-aquila/list';
import {NxCheckboxModule} from '@aposin/ng-aquila/checkbox';
import {NxCardModule} from '@aposin/ng-aquila/card';
import {NxtContactDataInputModule} from '@nxt/ngx-ndbx-extensions/contact-data-input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StylesModule} from '../styles/styles.module';
import {FeedbackModule} from '../shared/feedback/feedback.module';
import {KommDataChangeRoutingModule} from './komm-data-change-routing.module';
import {ContactDataTypePipe} from '../core/pipes/contact-data-type.pipe';
import {KommDataChangeMazComponent} from './components/komm-data-change-maz/komm-data-change-maz.component';
import {KommDataChangeSummaryComponent} from './components/komm-data-change-summary/komm-data-change-summary.component';
import {ScrollingService} from '../core/services/scrolling.service';
import {PhoneNumberFormatPipe} from '../core/pipes/mobile-number-format.pipe';
import {KommDataChangeSuccessComponent} from './components/komm-data-change-success/komm-data-change-success.component';
import {TranslateModule} from '@ngx-translate/core';
import {NxtSummaryModule} from '@nxt/ngx-ndbx-extensions/summary';
import {NxErrorModule} from '@aposin/ng-aquila/base';


@NgModule({
  declarations: [
    KommDataChangeMazComponent,
    KommDataChangeSummaryComponent,
    KommDataChangeSuccessComponent
  ],
  imports: [
    CommonModule,
    KommDataChangeRoutingModule,
    SharedModule,
    StylesModule,
    CommonModule,
    NxInputModule,
    NxFormfieldModule,
    NxIconModule,
    NxLinkModule,
    NxGridModule,
    NxButtonModule,
    NxMessageModule,
    NxHeadlineModule,
    NxListModule,
    NxCheckboxModule,
    NxCardModule,
    NxtContactDataInputModule,
    ReactiveFormsModule,
    FormsModule,
    FeedbackModule,
    NxtSummaryModule,
    TranslateModule,
    NxErrorModule
  ],
  providers: [
    ContactDataTypePipe, ScrollingService, PhoneNumberFormatPipe
  ]
})
export class KommDataChangeModule {
}
